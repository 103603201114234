<template>
  <div class="home py-10">
    <h1 class="text-center">Today Activities</h1>
    <div class="text-center pa-5" v-if="!forToday">There are no more activities scheduled for today.</div>
    <v-container v-else>
      <v-row>
        <v-col cols="12" md="6">
          <v-card
            color="green darken-1"
            dark
          >
            <v-card-title class="headline">Visit for Today</v-card-title>

            <v-card-subtitle>Today we will visit your establishment. Please be ready.</v-card-subtitle>
            <v-card-actions>
              <v-btn text to="/calendar">Check Next Routes</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
    name: 'Home',
    data: () => ({
       forToday: false,
    }),
    methods: {
        today: function(){
            var este = this;
            este.$http.post(este.$api + 'today', {
              customer_id: este.$store.getters.customer_id,
              token: este.$store.getters.token,
            }).then(function(response){
              console.log(response);
              if(response.status == 200 && response.data.status == 'ok'){
                  if(response.data.results > 0){
                      console.log(response.data.data);
                      este.forToday = true;
                  } else {
                      este.forToday = false;
                  }
              } else {
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": response.data.msg
                }
                este.$store.commit("msgMuestra", mensaje);
                if('error' in response.data && response.data.error == 'logout'){
                    este.$router.push('/logout');
                }
              }
            }).catch(function(err){
              var mensaje = {
                  "color": "red",
                  "timeout": 5000,
                  "message": "There was an error. Please try again."
              }
              este.$store.commit("msgMuestra", mensaje);
              window.console.log(err);
            });
        },
    },
    components: {
    },
    mounted: function(){
        if([0, 6].includes(this.$moment().day())){
            this.routes = [];
        } else {
            // Consulta actividades para el día de hoy
            this.today();
        }
        this.$store.commit('title', 'PRIMA');
    }
}
</script>
