import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import moment from 'moment'
import VueLocalStorage from 'vue-localstorage'
 
Vue.config.productionTip = false

Vue.prototype.$http = axios;
Vue.prototype.$moment = moment;
Vue.prototype.$http = axios;
if(process.env.NODE_ENV == 'development'){
    Vue.prototype.$api = 'http://prima.test/api/customer/';
} else {
    Vue.prototype.$api = 'https://primawm.com/api/customer/';
}

let options = {
  name: 'ls',
  storage: 'local',
};

Vue.use(VueLocalStorage, options);

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
  if(trace != undefined){
      console.log(trace);
  }
}

new Vue({
  router,
  store,
  vuetify,
  VueLocalStorage,
  moment,
  render: h => h(App)
}).$mount('#app')
console.log(process.env.NODE_ENV);
