import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/locations",
    name: "Locations",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Locations.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Calendar.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Invoices.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manifests",
    name: "Manifests",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Manifests.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/paybill",
    name: "Paybill",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Paybill.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/specialrequest",
    name: "Specialrequest",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Specialrequest.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/onlinetraining",
    name: "Onlinetraining",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Onlinetraining.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/dotonlinetraining",
    name: "Dotonlinetraining",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Dotonlinetraining.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/myprofile",
    name: "Myprofile",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Myprofile.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Logout.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  window.console.log(router);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //if (!router.app.$token) {
    if (!store.getters.token) {
      window.console.log("Requiere auth");
      next("/login");
      return;
    }
  }
  next();
});
export default router;
