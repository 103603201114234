import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      token: false,
      title: 'PRIMA',
      customer_id: 0,
      msg: {
          show: false,
          color: 'red',
          timeout: 5000,
          message: '',
      },
  },
  mutations: {
      logout: function(state){
          state.token = false;
          state.customer_id = 0;
      },
      login: function(state, datos){
          state.token = datos.token;
          state.customer_id = datos.customer_id;
      },
      msgMuestra: function(state, vars){
          state.msg.color = vars.color;
          state.msg.timeout = vars.timeout;
          state.msg.message = vars.message;
          state.msg.show = true;
      },
      msgOculta: function(state){
          state.msg.color = 'red';
          state.msg.timeout = 5000;
          state.msg.message = '';
          state.msg.show = false;
      },
      title: function(state, title){
          state.title = title;
      },
  },
  actions: {
  },
  getters: {
      token: state => state.token,
      customer_id: state => state.customer_id,
      msg: state => state.msg,
      title: state => state.title,
  },
  modules: {
  }
})
